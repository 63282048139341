.App {
  text-align: center;
  margin-top: 80px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  font-size: larger;
}
.preview {
  margin-top: 70px;
  margin-right: 20px;
}

.content{
  height: 100vh;
  width: 100vw;
  background-color: #282c34;
  display: flex;
  flex-direction: column;

}
.content-1{
  flex: 1;
  display: flex;
  align-items: center;

}
.content-1-1{
  color: white;
  margin-left: 50px;
  font-size: 20px;
}
.content-2{
  flex: 6;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: darkgrey;
}
.content-2-1{
  font-size: 50px;
}
.content-3{
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
.content-3-1{
  font-size: 15px;
  color:aliceblue;
  
}
.content-3-1-1{
  color:aliceblue;
  text-decoration: none;
}
.content-3-1-1:hover{
  color:cornflowerblue;
}
.content-3-2{
  
  
}
